function IconPin() {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.56278 7.12463V7.125H3.5625L3.56278 7.12463ZM0.715007 7.125C1.8966 7.12365 2.85 6.16941 2.85 4.9875V1.425H2.1375C1.74562 1.425 1.425 1.10438 1.425 0.7125C1.425 0.320625 1.74562 0 2.1375 0H9.2625C9.65437 0 9.975 0.320625 9.975 0.7125C9.975 1.10438 9.65437 1.425 9.2625 1.425H8.55V4.9875C8.55 6.16941 9.5034 7.12365 10.685 7.125H10.6875C11.081 7.125 11.4 7.444 11.4 7.8375C11.4 8.231 11.081 8.55 10.6875 8.55H6.39112V13.5375L5.67862 14.25L4.96612 13.5375V8.55H0.7125C0.318997 8.55 0 8.231 0 7.8375C0 7.444 0.318997 7.125 0.7125 7.125H0.715007Z"
        fill="#ABCBFC"
      />
    </svg>
  );
}
export default IconPin;
