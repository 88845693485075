import Profile from "../components/profile/Profile";

function ProfilePage() {
  return (
    <>
      <Profile />
    </>
  );
}

export default ProfilePage;
