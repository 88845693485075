function IconPlus() {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.4252 0H9.59128C3.58228 0 0 3.5805 0 9.5865V23.397C0 29.4195 3.58228 33 9.59128 33H23.4087C29.4177 33 32.9999 29.4195 32.9999 23.4135V9.5865C33.0165 3.5805 29.4342 0 23.4252 0ZM23.1115 17.7375H17.7463V23.1C17.7463 23.7765 17.1851 24.3375 16.5082 24.3375C15.8314 24.3375 15.2701 23.7765 15.2701 23.1V17.7375H9.90494C9.2281 17.7375 8.66682 17.1765 8.66682 16.5C8.66682 15.8235 9.2281 15.2625 9.90494 15.2625H15.2701V9.9C15.2701 9.2235 15.8314 8.6625 16.5082 8.6625C17.1851 8.6625 17.7463 9.2235 17.7463 9.9V15.2625H23.1115C23.7884 15.2625 24.3496 15.8235 24.3496 16.5C24.3496 17.1765 23.7884 17.7375 23.1115 17.7375Z"
        fill="#ABCBFC"
      />
    </svg>
  );
}
export default IconPlus;
