function IconCalender() {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.33062 7.61459H17.4494M6.87448 2.00022V3.83182M6.87448 3.83182L17.4992 3.83162M6.87448 3.83182C5.11407 3.83182 3.68714 5.28345 3.68722 7.07432L3.68772 17.8827C3.6878 19.6735 5.11483 21.1251 6.87512 21.1251H17.4998C19.2602 21.1251 20.6873 19.6733 20.6872 17.8824L20.6867 7.07401C20.6866 5.28327 19.2595 3.83162 17.4992 3.83162M17.4992 2.00012V3.83162M10.0627 17.3422V10.8571L7.93774 12.4783M15.9063 17.3422V10.8571L13.7813 12.4783"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default IconCalender;
